.mainCardContainer{
    display: flex;
    padding: 10px;
}
.cardContainer{
    justify-content: center;
    padding:20px;
    width: 30%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding-left:5px;
    position: relative;
    overflow: hidden;
    margin: 10px;
}
.imagemarj{
    margin-left: 30%;
}
.logi{
    
    font-weight: bold;
    text-align: center;
    padding-top: 15px;

}
.underlinesty{
    
    width: 210px;
    height: 2px;
    background-color: black;
    display: block;
    margin-left: auto;
    margin-right: auto;

}
.newhandi{
    text-align: center;
    width: 100%;
}
.margbot{
    margin-bottom: 100px;
}
.handpoint{
    display: inline-flex;
    margin-left: auto;
    margin-right: auto;
}
.logonew{
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.caredName{
    text-align: center;
    font-size: 20px;
    padding-top: 20px;
    font-family: IranSans;
    font-weight: 700;
    color: white;
}
.cardValue{
    font-size: 20px;
    font-weight: 600;
}
.circleOne{
    height: 120px;
    width: 120px;
    background: rgba(255, 255, 255, 0.379);
    border-radius: 60px;
    position: absolute;
    right: -22px;
    bottom: -36px; 
}
.circleTwo{
    height: 120px;
    width: 120px;
    background: rgba(255, 255, 255, 0.379);
    border-radius: 60px;
    position: absolute;
    right: -59px;
    top: -7px;
}