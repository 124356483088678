@font-face {
  font-family: "IranSans"; 
  font-style: normal; 
  font-weight: normal;
  src: url("fonts/IRANSansMobile(FaNum).ttf") format("truetype");
}
@font-face {
  font-family: "IranSans"; 
  font-style: Bold; 
  font-weight: normal;
  src: url("fonts/IRANSansMobile(FaNum)_Bold.ttf") format("truetype");
}
@font-face {
  font-family: "IranSans"; 
  font-style: normal; 
  font-weight: 300;
  src: url("fonts/IRANSansMobile(FaNum)_Light.ttf") format("truetype");
}
body,p,span,li,ul{
  font-family: 'IranSans';
  font-style: normal;
  font-weight: 300;
}
button,h6{
  font-family: 'IranSans';
  font-weight: normal;
  font-style: normal;
}
.maketop{
  margin-top: 15px;
}
h1,h2,h3,h4,h5{
  font-family: 'IranSans';
  font-style: Bold;
  text-align: right;
}
.changeicon{
  top: 4px;
  padding-left: 3px;
}
.lead{
  text-align: right;
}
ol{
  align-items: baseline;
}
oi{
  top:4px
}
.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-left: .5rem;
  color: #6c757d;
  content: "/";
}
.container {
  direction: rtl;
}
.mr-auto, .mx-auto {
  margin-left: auto !important;
}
.card-text{
  text-align: right;
  margin-top: 40px;
  margin-bottom: 60px;
}
.newcardi1{
  width: 50%;
  margin-left: 25px;
}
.newcardi2{
  margin-top: 40px;
  margin-bottom: 40px;
  margin-right: 25px;
  width: 50%;
}
.imaga{
  box-shadow: 5px 5px lightgreen;
  border-radius: 10px;
}
.newcardi3{
  display: flex;
}

.newcardi11{
  width: 100%;
  margin-left: 25px;
}
.newcardi22{
  margin-top: 40px;
  margin-bottom: 40px;
  margin-right: 25px;
  width: 100%;
}
.newcardi33{
  
}
.exam-style-head{
  list-style-type:none;
  margin-bottom: 20px;
}
.question-title{
  margin-bottom: 25px;
  margin-top: 10px;
  margin-right: 10px;
}
.answer-box{
  text-align: right;
  background-color: aqua;
  padding: 10px;
  border-radius: 10px;
}
.exam-style-body{
  background-color: bisque;
  text-align: right;
  padding: 15px;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.newdiv{
  margin-top: 50px;
  margin-bottom: 50px;
}
.deck-card {
  min-height: 250px;
}
.btni{
  bottom: 15px;
  left: 20px;
  position: absolute;
}
td{
  font-family: IranSans;
}
tr{
  font-family: IranSans;
}
.cardi{
  width: fit-content;
  background-color: darkviolet;
  padding: 5px;
  border-radius: 10px;
  color: aliceblue;
  padding-top: 10px;
  left: 15px;
  position: absolute;
}
.app-background {
  padding: 1rem;
  margin: 0;
  min-height: 100vh;
  background: #edc0bf;
  background: linear-gradient(90deg, #fb8cff 0, #a1ffe0 90%);
}
